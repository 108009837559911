
































import corporateClientModel from "@/api/corporate_client.model";
import SolutionAppointmentModel from "@/api/solution_appointment.model";
import { formatDayMonthYear } from "@/helpers/utils";
import ReportSolutionAppointmentModal from "@/layouts/components/ReportSolutionAppointmentModal.vue";
import { Component, Vue, Watch } from "vue-property-decorator";

import EntityCrud from "../entity/EntityCrud.vue";

@Component({
	components: { EntityCrud, ReportSolutionAppointmentModal },
})
export default class SolutionAppointmentReport extends Vue {
	model = SolutionAppointmentModel;
	corporateClientModel = corporateClientModel;
	title = "Agendamentos";
	isReadOnly: boolean = true;
	defaultEntity = {};
	kind = "solutionAppointmentTable";
	withEntityHeader = true;
	cleanFormKeys = {};
	isLoading = false;
	shouldShowHeaderName = false;

	shouldGoToPreviousPageAfterRemove = false;

	solutionAppointmentModalIsOpen: boolean = false;
	eventType: string = "";
	solutionAppointment: any = {};

	tableColumns = [
		{
			key: "returnDate",
			onTransform: formatDayMonthYear,
		},
		{
			key: "user.name",
			nameComplement: "user.lastName",
			name: "Usuário",
		},
		{
			key: "user.id",
			kind: "invisible",
		},
		{
			key: "solution.name",
			name: "Solução",
			keyEntity: "solution.id",
		},
		{
			key: "client.name",
			name: "Cliente",
			nameComplement: "client.lastName",
		},
		{
			key: "client.corporateClient.name",
			name: "Cliente corporativo(Cliente)",
			hasSelect: true,
			options: [{}],
		},
		{
			name: "Ações",
			kind: "actions",
			actions: [
				{
					icon: "PhoneOutgoingIcon",
					eventName: "changeReturnDate",
					type: "GuideBalloon",
				},
				{ icon: "RepeatIcon", eventName: "changeUser", type: "GuideBalloon" },
				{ icon: "ZapIcon", eventName: "changeReturnDateToToday", type: "GuideBalloon" },
			],
		},
	];

	async mounted() {
		const foundColumn = this.tableColumns.find(tableColumn => tableColumn.key === "client.corporateClient.name");
		if (foundColumn) {
			const corporateClients = await corporateClientModel.get("");
			foundColumn.options = corporateClients.map((corporateClient: any) => {
				return { name: corporateClient.name, value: corporateClient.id };
			});
		}
	}

	get currentId() {
		return this.$route.params.id;
	}

	@Watch("currentId")
	changeReadOnly() {
		if (!this.currentId) {
			this.isReadOnly = true;
		}
		if (this.currentId === "novo") {
			this.defaultEntity = {};
		}
	}

	actionsEvent(event: string, row: any) {
		this.solutionAppointment = row;
		this.eventType = event;

		this.solutionAppointmentModalIsOpen = true;
	}

	closeModal() {
		this.solutionAppointmentModalIsOpen = false;
	}

	onBack() {
		this.$router.back();
	}
}
