


































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CustomButton from "@/layouts/components/Button.vue";
import Select from "@/layouts/components/Select.vue";
import Input from "@/layouts/components/Input.vue";
import { formatDateToISO, toIsoDate } from "@/helpers/calendar";
import Calendar from "./Calendar.vue";
import { showErrorAlert } from "@/helpers";
import SolutionAppointmentModel from "@/api/solution_appointment.model";
import UserModel from "@/api/user.model";
import { CreateQueryParams } from "@nestjsx/crud-request";
import { IUser, UserRole } from "@/interfaces/user";
import CustomSelect from "@/layouts/components/Select.vue";
import debounce from "debounce";

@Component({
	components: {
		"custom-button": CustomButton,
		Select,
		"journey-input": Input,
		Calendar,
		"custom-select": CustomSelect,
	},
})
export default class ReportSolutionAppointmentModal extends Vue {
	@Prop() id: string;
	@Prop({ default: "" }) modalStyle!: string;
	@Prop({ default: "" }) title!: string;
	@Prop({ default: "" }) titlesStyle!: string;
	@Prop({ default: "" }) subtitle!: string;
	@Prop({ default: "" }) icon!: string;
	@Prop({ default: "" }) iconSize!: string;
	@Prop({ default: "mb-2" }) backButtonStyle!: string;
	@Prop({ default: "Voltar" }) backButtonText!: string;
	@Prop({ default: false }) isOpen!: boolean;
	@Prop({ default: false }) withBackButton!: boolean;
	@Prop({ default: null }) onBackButton!: Function;
	@Prop({ default: () => [] }) buttons!: any[];
	@Prop({ default: () => null }) onModalClosed!: () => void;
	@Prop({}) emitEventName: string;
	@Prop({}) eventType!: string;
	@Prop({}) solutionAppointment!: any;

	isModalOpen = this.isOpen;

	toIsoDate = toIsoDate;

	selectedDate: string = "";
	formattedSelectedDate: string = "";
	model = SolutionAppointmentModel;
	userModel = UserModel;

	userSearchParam: string = "";
	userList: { name: string; value: string; id: string }[] = [];

	selectedUser: any = {};

	userId: string = "";

	debouncedUserSearch = debounce(this.onSearch, 300);

	data() {
		return { isModalOpen: this.isOpen };
	}

	closeModal() {
		this.isModalOpen = false;
		if (this.onModalClosed && typeof this.onModalClosed === "function") {
			this.onModalClosed();
		}
	}
	@Watch("isOpen")
	async isOpenChanged() {
		this.isModalOpen = this.isOpen;

		if (this.isModalOpen) {
			if (this.eventType === "changeUser") {
				await this.loadUsers();
			}
		}
	}

	async created() {
		await this.isOpenChanged();
	}

	async loadUsers(name?: string, lastName?: string) {
		const queryParams: CreateQueryParams = this.getQueryParameters(name, lastName);
		const response = await this.userModel.search(queryParams);
		this.userList = response.data.length
			? response.data.map((user: IUser) => {
					return {
						name: `${user.name} ${user.lastName}`,
						value: user.id,
						id: user.id,
					};
			  })
			: this.userList;

		const foundUser = this.userList.find((user: any) => user.value === this.solutionAppointment["user.id"]);
		this.selectedUser = foundUser ?? {};
	}

	async inputChange(value: string) {
		this.userId = value;
	}

	async onSearch(search: string) {
		const [name, ...lastNameArray] = search.split(" ");
		const lastName =
			lastNameArray.length > 1 ? lastNameArray.join(" ") : lastNameArray.length ? lastNameArray[0] : undefined;
		await this.loadUsers(name, lastName);
	}

	async saveAction() {
		try {
			if (this.eventType === "changeReturnDate" && this.solutionAppointment.id && this.formattedSelectedDate) {
				await this.updateAppointmentReturnDate(this.solutionAppointment.id);
			} else if (this.eventType === "changeUser" && this.userId.length) {
				await this.updateUser(this.solutionAppointment.id, this.userId);
			} else if (this.eventType === "changeReturnDateToToday" && this.solutionAppointment.id) {
				await this.updateAppointmentReturnDate(this.solutionAppointment.id, true);
			}
			// Garantir persistência dos dados
			location.reload();
		} catch (error) {
			showErrorAlert((<any>error)?.message ?? "Ocorreu um erro. Tente novamente.");
		}
	}

	async updateAppointmentReturnDate(appointmentId: string, toToday = false) {
		if (toToday) {
			const today = new Date();
			this.formattedSelectedDate = formatDateToISO(today.toISOString().split("T")[0]);
		}
		await this.model.patch(appointmentId, "updateReturnDate", {
			returnDate: this.formattedSelectedDate,
		});
	}

	async updateUser(appointmentId: string, userId: string) {
		await this.model.patch(appointmentId, "updateUser", {
			user: { id: userId },
		});
	}

	getQueryParameters(name?: string, lastName?: string): CreateQueryParams {
		const $or = name ? [{ name: { $cont: name } }, { lastName: { $cont: lastName } }] : [];
		return {
			join: [],
			limit: 50,
			page: 1,
			search: {
				$or,
				role: {
					$in: [UserRole.HEALTH_PROMOTER],
				},
			},
			sort: [{ field: "name", order: "ASC" }],
		};
	}

	get todayIsoDate() {
		return toIsoDate(new Date());
	}

	updateReturnDate(date: string) {
		this.formattedSelectedDate = formatDateToISO(date);
	}
}
